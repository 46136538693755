import { Box } from '@inflearn/ds-react';
import { type HeadFC, navigate } from 'gatsby';
import { useEffect, useMemo } from 'react';
import EventCards from '../../../components/infcon-2024/EventCard';
import SkeletonInfoCards from '../../../components/infcon-2024/EventCard/SkeletonInfoCards';
import PageTitle from '../../../components/infcon-2024/PageTitle';
import EventPagesDto from '../../../dtos/EventPagesDto';
import useEventPages from '../../../hooks/useEventPages';
import { useViewInfconMain } from '../../../hooks/useViewInfconMain';
import { INFCON_2024_COLOR_PALETTE } from '../../../styles/color';
import { GATSBY_SITE_URL } from '../../../utils/env';
import { getCdnUrl } from '../../../utils/getCdnUrl';
import Seo from '../../../utils/Seo';
import { URLS } from '../../../utils/urls';

export const Head: HeadFC = () => {
  return (
    <Seo
      title="인프콘 2024 - 행사즐기기"
      description="다양한 이벤트 소식을 만나보세요"
      fullUrl={`${GATSBY_SITE_URL}${URLS.INFCON_2024__EVENT}`}
      image={getCdnUrl('infcon-2024-main/official-og.png')}
    />
  );
};

const Infcon2024EventPage = () => {
  const { data, isLoading, isError } = useEventPages(2024);
  useViewInfconMain();

  const events = useMemo(() => {
    if (isLoading || data == null) {
      return [];
    }

    return new EventPagesDto(data).pages;
  }, [isLoading, data]);

  useEffect(() => {
    if (isError) {
      void navigate('/404');
    }
  }, [isError]);

  return (
    <Box component="section" bg={'gray.0'}>
      <PageTitle
        title={'인프콘 이벤트'}
        subTitle={'INFCON Event'}
        color={INFCON_2024_COLOR_PALETTE.backgroundBlue}
      />
      {isLoading ? <SkeletonInfoCards /> : <EventCards cards={events} />}
    </Box>
  );
};

export default Infcon2024EventPage;
